@import '../../../styles/variables';

.logo {
    align-items: center;
    background: $verint-logo-background;
    display: flex;
    flex-direction: column;
    grid-area: logo;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    justify-content: center;

    &:hover {
        text-decoration: none;
    }
}

.logo-svg {
    fill: $verint-logo-foreground;
    height: 15px;
    margin-bottom: 5px;
    width: 88px;
}

.logo-title {
    color: $verint-logo-foreground;
    font-size: 13px;
    margin: 0;
    text-align: center;
}
