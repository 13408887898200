@import './mixins/border-radius';
@import './mixins/box-shadow';
@import './mixins/box-sizing';
@import './mixins/ellipsis';
@import './mixins/transform';
@import './mixins/transition';
@import './mixins/user-interface';

/* 100% height mixing */
@mixin height-100 {
    height: 100%;
    min-height: 100%;
}

/* screen size */
@mixin max-width($width) {
    @media screen and (max-width: $width) { @content; }
}
@mixin min-width($width) {
    @media screen and (min-width: $width) { @content; }
}

/* Clear child elements */
@mixin group {
    &:after {
        clear:both;
        content:"";
        display:table;
    }
}

/* Helpers */
@mixin remove-list {
    list-style: none;
    margin: 0px;
    padding-left: 0px;
}

@mixin link {
    &, &:visited, &:active {
        color: $verint-blue;
    }
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

@mixin antialiased {
    -webkit-font-smoothing: antialiased;
}

/* Checkboxes */
@mixin checkboxes($padding-left: 0, $padding-right: 0) {
    $background-left: -3px;
    .input.checkbox {
        display: none;
    }
    .input.checkbox + label {
        @include user-select(none);
        cursor: pointer;
        display: inline-block;
        font-weight: normal;
        line-height: 14px;
        margin-bottom: 0;
        min-height: 14px;
        /* min height-width so empty label can be used and still show background */
        min-width: 13px;
        padding: 0 $padding-right 0 $padding-left;
        vertical-align: middle;
        &:not(:empty) {
            margin-right: 5px;
            padding-left: $padding-left;
        }
        @if ($padding-left != 0) {
            margin-left: 0 - $padding-left;
        }
    }
    .input.checkbox + label:before {
        background: url('../legacy/images/inputs/checkbox.png') no-repeat $background-left -5px;
        content: "";
        display: inline-block;
        height: 15px;
        margin-right: 6px;
        vertical-align: middle;
        width: 13px;
    }
    .input.checkbox + label:active:before {
        background: url('../legacy/images/inputs/checkbox.png') no-repeat $background-left -30px;
    }
    .input.checkbox:checked + label:before {
        background: url('../legacy/images/inputs/checkbox.png') no-repeat $background-left -55px;
    }
    .input.checkbox:checked + label:active:before {
        background: url('../legacy/images/inputs/checkbox.png') no-repeat $background-left -80px;
    }
}

@mixin screen-reader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
