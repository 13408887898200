@import '../../../styles/custom_properties';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.main-nav {
    background-color: $white;
    display: -ms-grid;
    display: grid;
    grid-template-areas:
        'logo top-row'
        'logo space';
    -ms-grid-columns: $verint-header-logo-width 1fr;
    grid-template-columns: $verint-header-logo-width 1fr;
    grid-template-rows: $main-nav-top-row-height $secondary-nav-height;
    -ms-grid-ows: $main-nav-top-row-height $secondary-nav-height;
    height: $main-nav-height;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    /* z-index to be above than conversation panel */
    z-index: 4;

    .top-row {
        background-color: $grey-bg-1;
        display: flex;
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        grid-area: top-row;
        justify-content: space-between;
        overflow-x: auto;
    }

    ul {
        @include remove-list;
    }

    .main-nav-right {
        align-content: center;
        color: $base-font-colour;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin-left: 10px;
        min-width: 200px;
    }
}

.main-nav-bar {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    float: left;
    height: 40px;

    li.nav-item-logout,
    li.nav-item {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        float: left;
        font-size: 12px;
        height: 100%;
        justify-content: center;
        margin: 0 15px;
        padding: 0;
        position: relative;

        &,
        a {
            color: $base-font-colour;
            line-height: $main-nav-top-row-height - 2px;
            text-decoration: none;
        }
        &:hover,
        &.active {
            &:after {
                background-color: $verint-blue;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }
        a {
            &:before {
                margin-right: 5px;
            }
        }
        &.profile-logout {
            margin: 0;
            padding: 12px 5px;
            a:before {
                font-size: 17px;
            }
        }
        &.logged-in-as {
            background-color: $orange;
            color: $white;
            height: auto;
            line-height: initial;
            padding: 0.125rem 0.375rem;
            a {
                color: $white;
            }
        }
    }

    .profile-email {
        /* Different widths */
        @include min-width(1090px) {
            max-width: 285px;
        }
        @include min-width(1130px) {
            max-width: 325px;
        }
        align-items: center;
        display: flex;
        flex-shrink: 1;
        font-size: 12px;
        height: 100%;
        margin: 0 15px;
        overflow: hidden;
        padding: 0;
        position: relative;

        p {
            @include ellipsis;
            margin: 0;
            max-width: 100%;
            padding: 0;
        }
    }
}

.screen-reader {
    @include screen-reader;
}
